import React, { useEffect, useState } from 'react';
import DropDown from './DropDown';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { Us, De } from "react-flags-select";

export default function Nav({ setMobileToggle }) {

  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState('de');

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  },[]);

    const onLanSelect = (lan) => {
        setCurrentLanguage(lan)
        i18n.changeLanguage(lan)
    }

  return (
    <ul className="cs_nav_list fw-medium text-uppercase">
      <li>
        <Link to="/">{t('header_home')}</Link>
      </li>
      <li>
        <Link to="/about" onClick={() => setMobileToggle(false)}>
            {t('header_about')}
        </Link>
      </li>
      <li>
        <Link to="/service" onClick={() => setMobileToggle(false)}>
            {t('header_services')}
        </Link>
      </li>
      <li>
        <Link to="/contact" onClick={() => setMobileToggle(false)}>
            {t('header_contact')}
        </Link>
      </li>
      <li className="menu-item-has-children">
        <Link to="#">
          {currentLanguage === 'en' ? <Us /> : <De />}
        </Link>
        <DropDown>
          <ul>
            <li>
              <Link
                to="#"
                onClick={() => {
                    setMobileToggle(false);
                    onLanSelect('de');
                }}
              >
                <De /> Deutsch
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                    setMobileToggle(false);
                    onLanSelect('en');
                }}
              >
                <Us /> English
              </Link>
            </li>
          </ul>
        </DropDown>
      </li>

      {/*<li>*/}
      {/*  <Link to="/portfolio" onClick={() => setMobileToggle(false)}>*/}
      {/*    Portfolio*/}
      {/*  </Link>*/}
      {/*</li>*/}
      {/*<li className="menu-item-has-children">*/}
      {/*  <Link to="/blog" onClick={() => setMobileToggle(false)}>*/}
      {/*    Blog*/}
      {/*  </Link>*/}
      {/*  <DropDown>*/}
      {/*    <ul>*/}
      {/*      <li>*/}
      {/*        <Link to="/blog-grid" onClick={() => setMobileToggle(false)}>*/}
      {/*          Blog Grid*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/blog" onClick={() => setMobileToggle(false)}>*/}
      {/*          Blog List With Sidebar*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link*/}
      {/*          to="/blog/blog-details"*/}
      {/*          onClick={() => setMobileToggle(false)}*/}
      {/*        >*/}
      {/*          Blog Details*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </DropDown>*/}
      {/*</li>*/}

    </ul>
  );
}
