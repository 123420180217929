import emailjs from '@emailjs/browser';

const sendCustomEmail = async (emailData) => {
    try {
        emailjs.init('hXv02sC-sDAJyCPT9')
        emailjs
            .send('service_ijkvw97', 'template_q9xvhoc', emailData)
            .then((response) => {
                console.log('Email sent successfully. Response: ', response);
                return response;
            })
            .catch((error) => {
                console.error('Failed to send email. Error: ', error);
                return error;
            })
    } catch (error) {
        console.error('Failed to send email. Error: ', error);
        return error;
    }
}

export { sendCustomEmail };