import { useEffect } from 'react';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import AboutUsSection from '../Section/AboutSection';
import BrandSection from '../Section/BrandSection';
import ContactSection from '../Section/ContactSection';
import HeroSection from '../Section/HeroSection';
import MovingTextSection from '../Section/MovingTextSection';
import PortfolioSection from '../Section/PortfolioSection';
import PostSection from '../Section/PostSection';
import ServiceSection from '../Section/ServiceSection';
import TeamSection from '../Section/TeamSection';
import TestimonialSection from '../Section/TestimonialSection';
import WhyChooseUsSection from '../Section/WhyChooseUsSection';

const heroData = [
  {
    bgUrl: '/images/hero_slider_1.png',
    titleUp: 'hero_title_1_up',
    title: 'hero_title_1',
    subTitle: 'hero_subtitle_1',
  },
  {
    bgUrl: '/images/hero_slider_2.png',
    titleUp: 'hero_title_2_up',
    title: 'hero_title_2',
    subTitle: 'hero_subtitle_2',
  },
  {
    bgUrl: '/images/hero_slider_3.png',
    titleUp: 'hero_title_3_up',
    title: 'hero_title_3',
    subTitle: 'hero_subtitle_3',
  },
];

const movingTextData = [
  'moving_text_1',
  'moving_text_2',
  'moving_text_3',
  'moving_text_4',
  'moving_text_5',
];

const serviceData = [
  {
    iconUrl: '/images/icons/service_icon_1.svg',
    title: 'service_1_title',
    subTitle: 'service_1_subtitle',
    imgUrl: '/images/service_1.png',
    href: '/service/services-details',
  },
  {
    iconUrl: '/images/icons/service_icon_2.svg',
    title: 'service_2_title',
    subTitle: 'service_2_subtitle',
    imgUrl: '/images/service_2.png',
    href: '/service/services-details',
  },
  {
    iconUrl: '/images/icons/service_icon_3.svg',
    title: 'service_3_title',
    subTitle: 'service_3_subtitle',
    imgUrl: '/images/service_3.png',
    href: '/service/services-details',
  },
];

const portfolioData = [
  {
    imgUrl: '/images/portfolio_1.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
  },
  {
    imgUrl: '/images/portfolio_2.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
  },
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
  },
  {
    imgUrl: '/images/portfolio_4.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
  },
  {
    imgUrl: '/images/portfolio_3.jpeg',
    title: 'Marketing Web-design',
    projectUrl: '/portfolio/portfolio-details',
  },
];

const testimonialData = [
  {
    imgUrl: '/images/avatar_1.png',
    rating: '5',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Darlene Robertson',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_2.png',
    rating: '4.5',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Peter Johnson',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_3.png',
    rating: '4.5',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Max Lawrence',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_4.png',
    rating: '4',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Darlene Robertson',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_1.png',
    rating: '5',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Darlene Robertson',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_2.png',
    rating: '4.5',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Peter Johnson',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_3.png',
    rating: '4.5',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Max Lawrence',
    designation: 'Web design',
  },
  {
    imgUrl: '/images/avatar_4.png',
    rating: '4',
    desc: "I've been using [business name] for the past year and I'm so glad I did. Their products and services are top-notch and their customer service is amazing. I would highly recommend them to anyone",
    name: 'Darlene Robertson',
    designation: 'Web design',
  },
];

const teamData = [
  {
    imgUrl: '/images/team_member_1.jpeg',
    name: 'Ralph Edwards',
    designation: 'Web designer',
    srcUrl: '/team/team-details',
  },
  {
    imgUrl: '/images/team_member_2.jpeg',
    name: 'Wade Warren',
    designation: 'Marketing Coordinator',
    srcUrl: '/team/team-details',
  },
  {
    imgUrl: '/images/team_member_3.jpeg',
    name: 'Jane Cooper',
    designation: 'Creative Designer',
    srcUrl: '/team/team-details',
  },
];

const brandData = [
  { imgUrl: '/images/brand_1.svg' },
  { imgUrl: '/images/brand_2.svg' },
  { imgUrl: '/images/brand_3.svg' },
  { imgUrl: '/images/brand_4.svg' },
  { imgUrl: '/images/brand_5.svg' },
];

const postData = [
  {
    imgUrl: '/images/post_1.jpeg',
    title: 'What services does your business provide?',
    desc: 'We provide a range of digital marketing lutions including website design.',
    postUrl: '/blog/blog-details',
    postedBy: 'Admin',
    adminUrl: '/',
    numberOfComment: 3,
    commentUrl: '/blog/blog-details',
  },
  {
    imgUrl: '/images/post_2.jpeg',
    title: 'Introducing the latest tech features for you',
    desc: 'We provide a range of digital marketing lutions including website design.',
    postUrl: '/blog/blog-details',
    postedBy: 'Admin',
    adminUrl: '/',
    numberOfComment: 4,
    commentUrl: '/blog/blog-details',
  },
  {
    imgUrl: '/images/post_3.jpeg',
    title: 'The creative studio programm coming soon',
    desc: 'We provide a range of digital marketing lutions including website design.',
    postUrl: '/blog/blog-details',
    postedBy: 'Admin',
    adminUrl: '/',
    numberOfComment: 5,
    commentUrl: '/blog/blog-details',
  },
];

export default function Home() {
  pageTitle('Effizient Konsult KG');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSection data={heroData} />

      <Section>
        <MovingTextSection data={movingTextData} />
      </Section>

      <Section
        pt="133"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="cs_gray_bg"
        style={{ backgroundImage: 'url("/images/services_bg.png")' }}
      >
        <ServiceSection
          sectionTitleUp="service_section_title_up"
          sectionTitle="service_section_title"
          // sectionSubTitle="I have been a loyal customer of this auto parts company for years and I cannot recommend them enough. Their extensive selection of high-quality parts and accessories."
          sectionTitleDown="service_section_title_down"
          sectionBtnText="service_section_title_btn"
          sectionBtnUrl="/service"
          data={serviceData}
        />
      </Section>

      <Section
        pt="135"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="position-relative"
      >
        <AboutUsSection
          thumbUrl="/images/aboutus_1.png"
          experienceBgUrl="/images/aboutus_2.png"
          experienceIconUrl="/images/experience_icon.svg"
          experienceNumber="40"
          experienceTitle="Work Experience"
          sectionTitleUp="about_section_title_up"
          sectionTitle='about_section_title'
          sectionSubTitle="about_section_subtitle"
          grothTitle="Web development"
          grothPercentage="90"
          featureList={[
            {
              title: 'about_feature_1_title',
              subTitle: 'about_feature_1_subtitle',
            },
            {
              title: 'about_feature_2_title',
              subTitle: 'about_feature_2_subtitle',
            },
            {
              title: 'about_feature_3_title',
              subTitle: 'about_feature_3_subtitle',
            },
          ]}
          btnText="Get A Quote"
          btnUrl="/contact"
          videoBtnText="Watch the video"
          videoBtnUrl="https://www.youtube.com/embed/0Tz4Ycjbdbg"
        />
      </Section>

      {/*<Section pt="135" ptLg="" className="bg-gray">*/}
      {/*  <PortfolioSection*/}
      {/*    sectionTitle="Take a look our <br/>Completed Projects"*/}
      {/*    sectionTitleUp="Our Latest Project"*/}
      {/*    data={portfolioData}*/}
      {/*  />*/}
      {/*</Section>*/}

      {/*<Section*/}
      {/*  pt="135"*/}
      {/*  ptLg="75"*/}
      {/*  pb="100"*/}
      {/*  pbLg="40"*/}
      {/*  className="position-relative cs_iconbox_2_wrap overflow-hidden"*/}
      {/*>*/}
      {/*  <WhyChooseUsSection*/}
      {/*    imgUrlLeft="/images/why_choose_us_left_img.jpeg"*/}
      {/*    imgUrlRight="/images/why_choose_us_right_img.jpeg"*/}
      {/*    sectionTitleUp="Why Choose Us"*/}
      {/*    sectionTitle='Developing a <span className="text-accent"> design that <br /> is easy </span> to use and <br /> navigate'*/}
      {/*    sectionSubTitle="Providing legal advice, contract drafting, compliance*/}
      {/*    assistance, intellectual <br /> property protection, and other*/}
      {/*    legal support for businesses. Creating visual <br /> content,*/}
      {/*    such as logos, brochures, infographics."*/}
      {/*    services={[*/}
      {/*      {*/}
      {/*        imgUrl: '/images/icons/service_icon_5.svg',*/}
      {/*        title: 'Digital agency services',*/}
      {/*      },*/}
      {/*      {*/}
      {/*        imgUrl: '/images/icons/service_icon_6.svg',*/}
      {/*        title: 'Creative digital agency',*/}
      {/*      },*/}
      {/*      {*/}
      {/*        imgUrl: '/images/icons/service_icon_7.svg',*/}
      {/*        title: 'Super expert developers',*/}
      {/*      },*/}
      {/*      {*/}
      {/*        imgUrl: '/images/icons/service_icon_8.svg',*/}
      {/*        title: 'Creating a user friendly design',*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*</Section>*/}

      {/*<Section*/}
      {/*  pt="140"*/}
      {/*  ptLg="75"*/}
      {/*  pb="135"*/}
      {/*  pbLg="75"*/}
      {/*  style={{ backgroundImage: `url(${'/images/testimonial_bg.jpeg'})` }}*/}
      {/*>*/}
      {/*  <TestimonialSection*/}
      {/*    sectionTitleUp="Testimonial"*/}
      {/*    sectionTitle="What They’re Saying?"*/}
      {/*    sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses."*/}
      {/*    data={testimonialData}*/}
      {/*  />*/}
      {/*</Section>*/}

      {/*<Section pb="115" pbLg="55">*/}
      {/*  <TeamSection*/}
      {/*    data={teamData}*/}
      {/*    sectionTitle="Meet the professional team <br/> behind the success"*/}
      {/*    sectionTitleUp="Meet Our Team Member"*/}
      {/*  />*/}
      {/*</Section>*/}

      <Section
        pt="133"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="background-filled overflow-hidden"
        style={{ backgroundImage: `url(${'/images/cta_bg.png'})` }}
      >
        <ContactSection
          sectionTitleUp="contact_section_title_up"
          sectionTitle="contact_section_title"
          sectionSubTitle="contact_section_subtitle"
          textVarient="text-white"
          className="cs_mb_40"
          sectionImgUrl="/images/contact_img_1.png"
          contactInfo={[
            {
              imgUrl: '/images/icons/contact_icon_1.svg',
              titleUp: 'contact_phone_title',
              title: '+49 176 70029895',
              textColor: 'text-white',
              openLink: 'tel:+4917670029895'
            },
            {
              imgUrl: '/images/icons/contact_icon_2.svg',
              titleUp: 'contact_email_title',
              title: 'info@effizientkonsult.de',
              textColor: 'text-white',
              openLink: 'mailto:info@effizientkonsult.de'
            },
            {
              imgUrl: '/images/icons/contact_icon_3.svg',
              titleUp: 'contact_address_title',
              title: 'Kampwebersheide 47, 41564 Kaarst',
              textColor: 'text-white',
              openLink: 'https://maps.app.goo.gl/HS3rpHGefdhQ3tpx8'
            },
          ]}
        />
      </Section>

        {/*<Section pt="140" ptLg="80">*/}
        {/*  <BrandSection data={brandData} />*/}
        {/*</Section>*/}

      {/*<Section pt="115" ptLg="75" pb="110" pbLg="50">*/}
      {/*  <PostSection*/}
      {/*    data={postData}*/}
      {/*    sectionTitleUp="Find The Blogs"*/}
      {/*    sectionTitle="Find Out Latest News <br/> and Articles"*/}
      {/*  />*/}
      {/*</Section>*/}
    </>
  );
}
