import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import LocationInMap from '../LocationInMap';
import Section from '../Section';
import FormStyle2 from '../Form/FormStyle2';
import IconboxStyle2 from '../Iconbox/IconboxStyle2';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

import { useTranslation } from 'react-i18next';

const locationData =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4997.006008408668!2d6.631072876865753!3d51.22823053105891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8b3f192c45d5f%3A0xd6bf389175d7466c!2sKampwebersheide%2047%2C%2041564%20Kaarst!5e0!3m2!1sen!2sde!4v1722668817771!5m2!1sen!2sde';

export default function ContactPage() {

  const { t } = useTranslation();

  pageTitle(t('header_contact'));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Breadcrumb title={t('header_contact')} bgUrl="/images/breadcrumb_header_bg.png" />
      <Section pt="133" ptLg="75" pb="133" pbLg="75">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <SectionHeadingStyle3
                sectionTitleUp={t('contact_phone_title')}
                sectionTitle={t('contact_form_2_title')}
              />
              <FormStyle2 />
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="cs_pl_60 cs_pl_lg_0 cs_pt_lg_60">
                <SectionHeadingStyle3
                  sectionTitleUp={t('contact_section_title_up')}
                  sectionTitle={t('contact_section_title')}
                  sectionSubTitle={t('contact_section_subtitle')}
                  className="cs_mb_40"
                />
                <div className="cs_iconbox_wrapper cs_style1">
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_1.svg"
                    title="+49 176 70029895"
                    titleUp={t('contact_form_title')}
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_2.svg"
                    title="info@effizientkonsult.de"
                    titleUp={t('contact_email_title')}
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_3.svg"
                    title="Kampwebersheide 47, 41564 Kaarst"
                    titleUp={t('contact_address_title')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <LocationInMap data={locationData} />
    </>
  );
}
