import React from 'react';
import { useTranslation } from 'react-i18next';
import {sendCustomEmail} from "../../helpers/email";

export default function ContactForm({ variant }) {

  const { t } = useTranslation();

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('17670029895');
  const [subject, setSubject] = React.useState('Contact Form Submission');
  const [message, setMessage] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!name) {
      alert('Please enter your name');
    } else if(!email) {
      alert('Please enter your email');
    } else if(email.indexOf('@') === -1) {
      alert('Please enter a valid email');
    } else if(!message) {
      alert('Please enter your message');
    } else {
      sendCustomEmail({
        reply_to: email,
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message
      })
          .then(() => {
            alert('Email sent successfully');
            setEmail('');
            setName('');
            setPhone('');
            setSubject('');
            setMessage('');
          })
          .catch((error) => {
            console.error('Failed to send email. Error: ', error);
          })
    }
  }

  return (
    <form
      action="#"
      className={`cs_contact_form bg-white cs_pt_64 cs_pl_80 cs_pr_80 cs_pb_80 cs_pl_lg_30 cs_pr_lg_30 position-relative cs_rounded_20 ${
        variant ? variant : ''
      }`}
      style={{ backgroundImage: `url('/images/contact_bg_pattern.svg')` }}
    >
      <div className="cs_section_heading cs_style_1 d-flex align-items-center text-center cs_mb_30">
        <div className="cs_section_heading_in">
          <h2 className="cs_fs_48 cs_fs_lg_36 m-0">{t('contact_form_title')}</h2>
        </div>
      </div>
      <input
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_15 bg-gray"
        type="text"
        placeholder={t('contact_form_name')}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_15 bg-gray"
        type="email"
        placeholder={t('contact_form_email')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_30 bg-gray"
        placeholder={t('contact_form_msg')}
        cols={30}
        rows={4}
        defaultValue={''}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden" onClick={handleSubmit}>
        <span>{t('contact_form_submit')}</span>
      </button>
    </form>
  );
}
