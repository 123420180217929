import React from 'react';
import Service from '../../Service';
import ServiceInfo from '../../ServiceInfo';
import SectionHeading from '../../SectionHeading';

import { useTranslation } from 'react-i18next';

export default function ServiceSection({
  sectionTitleUp,
  sectionTitle,
  sectionSubTitle,
  sectionTitleDown,
  sectionBtnText,
  sectionBtnUrl,
  data,
  textVariant,
}) {

    const { t } = useTranslation();

  return (
    <div className="container">
      {sectionTitle && (
        <SectionHeading
          sectionTitleUp={t(sectionTitleUp)}
          sectionTitle={t(sectionTitle)}
          // sectionSubTitle={sectionSubTitle}
        />
      )}

      <div className="row">
        {data?.map((item, index) => (
          <div className="col-xl-4 col-md-6" key={index}>
            <Service {...item} />
          </div>
        ))}
      </div>
      <ServiceInfo
        sectionTitleDown={t(sectionTitleDown)}
        sectionBtnText={t(sectionBtnText)}
        sectionBtnUrl={sectionBtnUrl}
        textVariant={textVariant}
      />
    </div>
  );
}
