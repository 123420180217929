import React from 'react'
import {sendCustomEmail} from "../../helpers/email";

export default function FormStyle2() {

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [message, setMessage] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!name) {
            alert('Please enter your name');
        } else if(!email) {
            alert('Please enter your email');
        } else if(email.indexOf('@') === -1) {
            alert('Please enter a valid email');
        } else if(!subject) {
            alert('Please enter your subject');
        } else if(!message) {
            alert('Please enter your message');
        } else if(!phone) {
                alert('Please enter your phone number');
        } else if(phone.length < 10) {
            alert('Please enter a valid phone number');
        } else {
            sendCustomEmail({
                reply_to: email,
                name: name,
                email: email,
                phone: phone,
                subject: subject,
                message: message
            })
                .then(() => {
                    alert('Email sent successfully');
                    setEmail('');
                    setName('');
                    setPhone('');
                    setSubject('');
                    setMessage('');
                })
                .catch((error) => {
                    console.error('Failed to send email. Error: ', error);
                })
        }
    }

  return (
    <form className="row">
      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="col-lg-12">
        <textarea
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_40 bg-gray"
          placeholder="Message here ..."
          cols={35}
          rows={7}
          defaultValue={""}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden" onClick={handleSubmit}>
          <span>Send A Message</span>
        </button>
      </div>
    </form>
  )
}
